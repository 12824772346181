.CalendarDay__selected_span {
    background: #7f91f5; 
    color: #fff; 
    border: none;

  }
   
  .CalendarDay__selected {
    background: #3f51b5;
    color: #fff;
    border: none;
  }

   
   
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #7f91f5 ;
    color: #fff;
    border: none;
  }

  .CalendarDay__selected_span:hover, .CalendarDay__selected:hover {
    background: #3f51b5;
    color: #fff;
    border: none;
  }

  .CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: none;
    color: #82888a;
}

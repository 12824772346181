.termCard{
    word-wrap:break-word;
    color: #495057;
     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
     background-color: #fff;
     width:100%;
     font-weight:400;
     line-height:1.5;
     border: 1px solid #ced4da;
     border-radius:0.25rem;
     padding: 0.375rem 0.75rem;
      }